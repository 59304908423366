.header{
    background:#00a49d;
    padding: 10px 0;
}
.header-innr{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-menu ul{
    margin: 0 -20px;
    padding-top: 5px;
}
.nav-menu ul li{
    float: left;
    padding: 0 20px;
}
.nav-menu ul li a{
    display: inline-block;
    vertical-align: baseline;
    text-decoration: none;
    font-size: 20px;
    line-height: 1.1;
    color: #fff;
}
.nav-menu ul li a:hover{
    color: #ed193f;
    transition: all 0.5s;
    -ms-transition: all 0.5s;
    -webkit-transition: all 0.5s;
}
