.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.block-414{
  max-width: 414px;
  width: 100%;
  margin: 0 auto;
}
.page-content-wrp {
	padding: 50px 20px 30px;
	text-align: justify;
}
.page-content-wrp strong{
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: 20px;
}
.page-content-wrp img {
  max-width: 150px;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding-bottom: 20px;
}

.homepage-img-wrp{
  position:relative;
}
.homepage-img-wrp:after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background:rgba(0, 0, 0, 0.5);

}
.homepage-img-wrp img{
  max-width: 100%;
  width: 100%;
}
.homepage-dsc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  text-align: center;
  color: #fff;
  z-index: 999;
}
.homepage-dsc h1 {
  font-size: 60px;
  line-height: 1;
  color: #fff;
  font-weight: 700;
}
.homepage-dsc span{
  display: inline-block;
  vertical-align: baseline;
  font-size: 20px;
  line-height: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*
-----------------
New Css
----------------- 
*/
body{
  max-width: 100%;
}
.block-1280{
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
.container{
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
.reset-list{
    margin: 0;
    padding: 0;
    list-style: none;
}
