@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.block-414{
  max-width: 414px;
  width: 100%;
  margin: 0 auto;
}
.page-content-wrp {
	padding: 50px 20px 30px;
	text-align: justify;
}
.page-content-wrp strong{
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: 20px;
}
.page-content-wrp img {
  max-width: 150px;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding-bottom: 20px;
}

.homepage-img-wrp{
  position:relative;
}
.homepage-img-wrp:after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background:rgba(0, 0, 0, 0.5);

}
.homepage-img-wrp img{
  max-width: 100%;
  width: 100%;
}
.homepage-dsc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  text-align: center;
  color: #fff;
  z-index: 999;
}
.homepage-dsc h1 {
  font-size: 60px;
  line-height: 1;
  color: #fff;
  font-weight: 700;
}
.homepage-dsc span{
  display: inline-block;
  vertical-align: baseline;
  font-size: 20px;
  line-height: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/*
-----------------
New Css
----------------- 
*/
body{
  max-width: 100%;
}
.block-1280{
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
.container{
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
.reset-list{
    margin: 0;
    padding: 0;
    list-style: none;
}

.footerImg {

}
 .footerImg img {
     max-width: 120px;
     width: 100%;
     margin:  10px auto 0;
 }
.dLogo {
    padding: 20px 0 25px;
    text-align: center;
}
.dLogo img {
    max-width: 100px;
    width: 100%;
    margin: 0 auto;
}

@media (max-width: 320px) {
    .mbContainer .singleTv .itemImg {
        width: 50px;
        height: 50px;
    }
}
.countryselect {
    padding: 25px 21px 25px;
    text-align: right;
}
.flag {
    top: 3px;
    width: 45px;
}

.countryList {
    height: 220px;
    overflow-x: hidden;
    margin: 0;
    list-style: none;
    padding: 10px 30px;
}

.dropdown_list {
    padding: 0 20px;
}
.dropdown_list .dropdown {
    /*background: #fff;*/
    /*border-radius: 5px;*/
    /*padding: 10px 20px;*/
    cursor: pointer;
    /*float: right;*/
}

.toggle {
    width: 100%;
}

.isLoading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 10;
}

.isLoading i {
    font-size: 50px;
    color: #fff;
    -webkit-animation: round 2s linear infinite;
            animation: round 2s linear infinite;
}

.player {
    width: 100%;
    background: #fff;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
}

.dropdown-menu {
    min-width: 7rem;
}


@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(-360deg);
                transform: rotate(-360deg);
    }
}


@keyframes round {
    0% {
        -webkit-transform: rotate(-360deg);
                transform: rotate(-360deg);
    }
}
body {
  background-color: #e4e4e4;
  font-family: Open Sans;
  /* max-width: 414px; */
  width: 100%;
  margin: 0 auto;
}

a {
  text-decoration: none !important;
}

.cusRow {
  margin: 0 -5px;
}

.cusCol {
  padding: 0 5px;
}

.mbContainer {
}

.mbContainer .singleItem {
  max-width: 20%;
  width: 100%;
  -webkit-flex: 0 0 20%;
          flex: 0 0 20%;
  margin-bottom: 15px;
  padding: 0 5px;
}

.mbContainer .singleItem .title {
  font-size: 8px;
  color: #666;
  text-align: center;
}

.mbContainer .singleItem .itemImg {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 60px;
  height: 60px;
  padding: 7px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 5px;
}

.mbContainer .singleItem .itemImg img {
  border-radius: 10px;
}

.whatsNewWrap {
}

.whatsNewItemInner {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;
  display: block;
}

.whatsNewItemSlide {
  overflow-x: scroll;
  overflow-y: hidden;
  display: -webkit-flex;
  display: flex;
  margin: 0 -5px;
}

.whatsNewItemSlide::-webkit-scrollbar {
  display: none;
}

.whatsNewItemSlide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.whatsNewItem {
  min-width: 335px;
  width: 100%;
  padding: 0 5px;
}

.whatsNewItem .itemImg {
  min-height: 178px;
  max-height: 178px;
  overflow: hidden;
}

.whatsNewItem .itemImg img {
  height: 100%;
  width: 100%;
}

.info {
  margin-top: 10px;
  padding: 5px 15px 10px;
}

.whatsNewItem .title {
  color: #878585;
  font-size: 12px;
}

.exPlore .linked {
  font-size: 13px;
  color: red;
  letter-spacing: 0.5px;
}

.exPlore span {
  font-size: 14px;
  color: #878585;
}

h2 {
  font-size: 14px;
  font-weight: 300;
  color: #666;
  text-align: left;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 42px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* .content {
  padding-bottom: 30px;
} */

/*  robiNewsWrap  */
.robiNewsWrap {
}

.robiNewsWrapItemSlide {
  overflow-x: scroll;
  overflow-y: hidden;
  display: -webkit-flex;
  display: flex;
  margin: 0 -5px;
}

.robiNewsWrapItemSlide::-webkit-scrollbar {
  display: none;
}

.robiNewsWrapItemSlide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.robiNewsWrapItem {
  min-width: 335px;
  width: 100%;
  padding: 0 5px;
  position: relative;
}

.robiNewsWrapItem .shareIcon {
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-size: 14px;
  color: #999;
}

.news24Item {
  position: relative;
}

.news24Item .shareIcon {
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-size: 14px;
  color: #999;
}

.robiNewsWrapItemInner {
  background: #fff;
  display: block;
  padding: 10px;
  border-radius: 10px;
}

.robiNewsWrapItemInner img {
  max-width: 50px;
  /*width: 100%;*/
  /*height: 40px;*/
  border-radius: 8px;
}

.itemInnerWrap {
}

.itemInnerWrap .info {
  margin: 0;
  padding: 0 0 0 10px;
}

.itemInnerWrap .info p {
  font-size: 10px;
  color: #666;
  margin: 0;
}

.itemInnerWrap .info .title {
  font-size: 14px;
  color: #444;
}

/*   history_33    */
.history_33 {
}

.history_33ItemSlide {
  overflow-x: scroll;
  overflow-y: hidden;
  display: -webkit-flex;
  display: flex;
  margin: 0 -5px;
}

.history_33ItemSlide::-webkit-scrollbar {
  display: none;
}

.history_33ItemSlide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.history_33Item {
  min-width: 335px;
  width: 100%;
  padding: 0 5px;
  position: relative;
}

.history_33ItemInner {
  background: #fff;
  display: block;
  border-radius: 10px;
  overflow: hidden;
}

.history_33ItemInner .info {
  margin: 0;
  padding: 20px;
}

.history_33ItemInner .info .title {
  font-size: 14px;
  color: #444;
  margin-bottom: 10px;
}

.history_33ItemInner .info p {
  font-size: 10px;
  color: #666;
  margin: 0 0 10px;
}

.history_33ItemInner .itemImg {
  max-width: 150px;
  width: 100%;
  height: 100%;
  margin-left: auto;
}

.history_33ItemInner .itemImg img {
  height: 175px;
  width: 100%;
}

/**
news24Item
 */
.news24Item {
  margin-bottom: 10px;
}

/*    .news24Item .shareIcon {
        position: absolute;
        right: 20px;
        bottom: 10px;
        font-size: 14px;
        color: #999;
    }*/

.searchBox {
  position: relative;
}

.searchBox input.form-control {
  border-radius: 50px !important;
  height: 50px;
}

.searchBox .btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: transparent;
  color: #f3414b;
}

.durontoSearchBox {
  padding: 20px 0;
}

@media (max-width: 320px) {
  .mbContainer .singleItem .itemImg {
    width: 50px;
    height: 50px;
  }
}

button:focus {
  box-shadow: none;
  outline: none;
}

.searchBox {
  position: relative;
  z-index: 1;
}

.searchBox .form-control {
  background: transparent;
  padding: 0 30px;
}

.searchBox .form-control:focus {
  border-color: #666;
  box-shadow: none !important;
}

.tabHeader {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}

.searchBox .searchIcon {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 70px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 0.5;
  z-index: -1;
}

.searchBox .searchIcon i {
  color: #f20101;
}

.success_img {
  background: url("http://cms.duronto.com.bd/backend/dist/img/success.jpg");
  height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.success_text {
  margin-bottom: 550px;
}

.error_img {
  background: url("http://cms.duronto.com.bd/backend/dist/img/404.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.error_text {
  margin-top: 300px;
}

.content .pageNotFound {
  padding-top: 120px;
  text-align: center;
}
.btn_custom {
  background-color: #00897a;
}

.otpheader{
    text-align: center;
}
.otpheader img{
    width: 100%;
    max-width: 200px;
}
/*#partitioned {*/
/*    padding-left: 8px;*/
/*    text-align: center;*/
/*    letter-spacing: 42px;*/
/*    border: 0;*/
/*    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);*/
/*    background-position: bottom;*/
/*    background-size: 50px 1px;*/
/*    background-repeat: repeat-x;*/
/*    background-position-x: 35px;*/
/*    width: 65%;*/
/*}*/
/**:focus {*/
/*    outline: none;*/
/*}*/
.cusRow {
    margin: 0 -5px;
}

.cusCol {
    padding: 0 5px;
}

.mbContainer {
}

.mbContainer .singleTv {
    max-width: 20%;
    width: 100%;
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    margin-bottom: 15px;
    padding: 0 5px;
}

.mbContainer .singleTv .title {
    font-size: 8px;
    color: #666;
    text-align: center;
}

.mbContainer .singleTv .itemImg {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 60px;
    height: 60px;
    padding: 7px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 5px;
}

.mbContainer .singleTv .itemImg img {
    border-radius: 10px;
}

@media (max-width: 320px) {
    .mbContainer .singleTv .itemImg {
        width: 50px;
        height: 50px;
    }
}
.topBar {
    background: #fff;
    padding: 20px 15px;
}

.topBarInner {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.homeIcon {
    font-size: 25px;
    color: black;
}

.title {
    font-size: 18px;
    font-weight: 600;
}
.title .badge {
    margin-left: 10px;
}


/*new tv*/
.tvWrap {

}
.tvSlide {
    overflow-x: scroll;
    overflow-y: hidden;
    display: -webkit-flex;
    display: flex;
    margin: 0 -5px;
}

.tvSlide::-webkit-scrollbar {
    display: none;
}

.tvSlide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tvItem {
    min-width: 335px;
    width: 100%;
    padding: 0 5px;
}

.tvItem .itemImg {
    min-height: 178px;
    max-height: 178px;
    overflow: hidden;
}

.tvItem .itemImg img {
    height: 100%;
    width: 100%;
}

.info {
    margin-top: 10px;
    padding: 5px 15px 10px;
}

.tvItem .title {
    color: #878585;
    font-size: 12px;
}

.tvcusCol {
    padding: 0 5px;
}
.quiz_header {
  text-align: center;
  position: fixed;
  top: -78px;
  left: -32px;
  right: -167px;
}
.quiz_header img {
  width: 100%;
  height: 300px;
}
.quizWinner {
  text-align: center;
}
.quizWinner img {
  width: 100%;
  height: 222px;
}
.duronto_btn {
  width: 180px;
  height: 48px;
  background: #00897a;
  border-radius: 24px;
  transition: color 0.3s ease, border-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  color: #fff;
  cursor: pointer;
}

.duronto_btn2 {
  background: #00897a;
  border-radius: 24px;
  color: #fff;
  transition: color 0.3s ease, border-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  cursor: pointer;
}

.duronto_btn_success {
  border-radius: 24px;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  transition: color 0.3s ease, border-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  cursor: pointer;
}
.duronto_next_btn {
  width: 180px;
  height: 48px;
  background: #00897a;
  border-radius: 24px;
  transition: color 0.3s ease, border-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  color: #fff;
  cursor: pointer;
}
.duronto_txt {
  color: #00897a;
}

.duronto_yes_btn {
  width: 100px;
  height: 47px;
  background: #1e7e34;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  margin-right: 50px;
  font-size: 22px;
}

.duronto_no_btn {
  width: 100px;
  height: 47px;
  background: #00897a;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  font-size: 22px;
}

.duronto_radio {
  border: 0px;
  width: 100%;
  height: 2em;
}

.footer_quiz {
  /*text-align: center;*/
  /*margin: 20px 0;*/
  /*position: relative;*/
  /*bottom: -188px;*/
}

.footer_quiz img {
  position: fixed;
  bottom: -100px;
  right: -50px;
}

.mainBackground {
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  background-image: url("http://localhost/browser/public/backend/dist/img/bubble.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.counter {
  color: #ffffff;
  font-weight: 500;
  font-size: 25px;
  text-align: right;
  padding-right: 25px;
  padding-top: 5px;
  float: right;
}

.label {
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  padding-top: 5px;
  float: left;
}

.lifeTIme {
  position: fixed;
  top: 2%;
  left: 50%;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.lifeTImeLabel {
  color: #00897a;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}

.labelOne {
  /*margin-left: auto;*/
  margin-top: 130px;
  /*position: relative;*/
  /*top: 100px;*/
  text-align: justify;
}
.labelOneTop {
  color: white;
  margin-top: 30px;
}
.welcome {
  color: red;
  font-size: 22px;
}
.labelOneltext {
  text-align: justify;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.labelContinue {
  text-align: center;
}

.timeOutAndAd {
  padding: 0;
  margin-top: 191px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
  top: 100px;
}

.timeOutAndAd img {
  width: 100%;
  height: 90px;
}

.timeOutAndAd .timeOutText {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
}

.timeOut {
  margin-left: auto;
  margin-right: auto;

  width: 8em;
  padding: 0;
  margin-top: 191px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
  top: 100px;

  //text-align: center;
}

.timeOut span {
  margin-left: 5px;
  font-size: 26px;
  font-weight: 700;
}

.timeOut img {
  width: 100%;
  height: 90px;
}

.adText {
  text-align: center;
}

.quiz_rule_heder {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #00897a;
}

.quiz_rule {
  line-height: 32px;
}

.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 10;
}

.asdasdasd > span {
  display: -webkit-flex;
  display: flex;
}

.asdasdasd span span {
  margin-right: 10px;
}
.asdasdasd span.kjkj {
  margin: 0;
}

.failHeader {
  font-size: 28px;
  font-weight: 600;
  color: #00897a;
  padding: 30px 0px 30px 0px;
}

.quizTypeHeader {
  font-size: 28px;
  font-weight: 600;
  color: #00897a;
  padding: 30px 0px 30px 0px;
}

.quizTypeText {
  padding-top: 12px;
}

.quizTypeButton {
  padding-top: 33px;
}

.quizTypeButtonKnow {
  padding-top: 100px;
}

.duronto_type_btn {
  width: 140px;
  height: 48px;
  font-size: 1.25rem;
  background: #00897a;
  border-radius: 24px;
  transition: color 0.3s ease, border-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  color: #fff;
  cursor: pointer;
}

.duronto_know_banagladesh_btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 48px;
  font-size: 1.25rem;
  background: #00897a;
  border-radius: 24px;
  transition: color 0.3s ease, border-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  color: #fff;
  cursor: pointer;
}

.duronto_subject_btn {
  width: 100%;
  height: 48px;
  font-size: 1.25rem;
  background: #00897a;
  border-radius: 24px;
  transition: color 0.3s ease, border-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
  color: #fff;
  cursor: pointer;
}

.prigeText {
  padding-top: 2px;
}

.fade.model-ad.modal {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #000;
}

.fade.model-ad.modal .modal-dialog.modal-md {
  margin: 0;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: #000;
  position: relative;
  max-width: 100%;
}

.close-btn {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #00897a;
  font-weight: 700;
}

.replay-narrow .replay-controls-bar {
  height: 0px !important;
  -webkit-align-items: end;
          align-items: end;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.footerRobi {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 600px;
  margin-left: -299px;
}

.footerRobi img {
  max-width: 130px;
  width: 100%;
  margin: 10px auto 0;
}



#question {
    /*background-color: #f56f18;*/
    color: white;
    padding: 0px;
    text-align: left;
    position: absolute;
    top: 35px;
}

#question h4{
    background-color: #fff;
    color: #0b0e21;
    display:inline-block;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
}

#question p{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: justify;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 4px;
}

#answers ul{
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;

    position: relative;
    top: 180px;
}

#answers li {
    background-color: #fff;
    border: 2px solid #ffffff;
    min-height: 45px;
    width: 48%;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 22px;
    transition: color .3s ease, border-color .3s ease, -webkit-transform .3s ease;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    transition: color .3s ease, border-color .3s ease, transform .3s ease, -webkit-transform .3s ease;
    cursor: pointer;
    border-radius: 50px;
}

#answers li span {
    /*background-color: #f56f18;*/
    color: #fff;
    /*font-size: 30px;*/
    -webkit-flex: 75px 1;
            flex: 75px 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    transition: background-color .3s ease;
}

#answers li p {
    color: #0b0e21;
    font-size: 16px;
    font-weight: 600;
    -webkit-flex: calc(100% - 75px) 1;
            flex: calc(100% - 75px) 1;
    margin: auto 20px;
    transition: color .3s ease;
}

#answers li:after{
    display: block;
}

#answers li:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}

#answers li.right {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.right span {*/
/*    background-color: #ffffff;*/
/*}*/

#answers li.right p {
    color: #ffffff;
}

#answers li.wrong {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.wrong span {*/
/*    background-color: #dc3545;*/
/*}*/

#answers li.wrong p {
    color: #ffffff;
}

#submit {
    text-align: center;
    margin: 0px 0;
    position: relative;
    bottom: -165px;
}

.fancy-btn {
    border: 2px solid #0b0e21;
    border-radius: 0;
    background-color: #0b0e21;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 75px;
    margin: 0 auto;
    text-transform: uppercase;
    transition: color .2s ease, background-color .2s ease;
}

.fancy-btn:hover {
    background-color: #fff;
    color: #0b0e21;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.popup {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.popup h1 {
    background-color: #f56f18;
    color: white;
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin-top: 0;
}

.popup p {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 20px 10% 0;
}

.popup .fancy-btn{
    margin: 20px auto;
}

footer {
    background-color: #0b0e21;
    color: white;
    letter-spacing: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
}
footer p {
    margin: 0;
}

footer small {
    margin-bottom: 10px;
}

footer span {
    color: crimson;
}

@media (max-width: 768px) {
    #question p {
        padding-left: 0px;
        padding-right: 10px;
    }

    #answers li {
        width: 100%;
    }

    #submit button {
        /*width: 50%;*/
        /*font-size: 20px;*/
        /*font-weight: 600;*/
        cursor: pointer;
    }
}
@media (max-width: 480px) {

    footer {
        padding: 5px;
    }

    footer small {
        display: none;
    }
}
.palyer_footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    /*background-color: red;*/
    color: white;
    text-align: center;
}

.replay-controls-bar, .replay-selector-items, .replay-exit-button, .replay-playback-monitor-close-button, .replay-buffering-indicator, .replay-goto-live-button.replay-toggled-on:hover {
    color: #bbb;
}

.replay-slider-handle {
    background-color: #bbb;
}

.replay {
    background-color: #222;
}

.replay-controls-bar, .replay-selector-items {
    background-color: rgba(32,32,32,0.8);
}

.replay-slider-track, .replay-selector-item:hover {
    background-color: #888;
}

.replay-selector-item:focus > * {
    background-color: #888;
}

.replay-slider-handle:hover {
    background-color: white;
}

.replay-slider:focus .replay-slider-handle {
    background-color: white;
}

.replay-timeline-information .replay-timeline-tooltip {
    background-color: rgba(32,32,32,0.8);
}

.replay-button:hover, .replay-toggle-button:hover {
    color: white;
}

.replay-button:focus > *, .replay-toggle-button:focus > * {
    color: white;
}

.replay-button:focus, .replay-button > :focus, .replay-toggle-button:focus, .replay-toggle-button > :focus,
.replay-slider:focus, .replay-slider-handle:focus, .replay-selector-item:focus, .replay-selector-item > :focus {
    outline: none;
}

.replay-goto-live-button.replay-toggled-on:hover {
    cursor: default;
}

.replay-time-display span:nth-child(2) {
    border-top-color: rgba(128,128,128,0.5);
}

.replay-playback-monitor {
    color: white;
    background-color: rgba(20, 20, 20, 0.9);
    cursor: default;
}

td.replay-playback-monitor-current-value {
    opacity: 0.9;
}

td.replay-playback-monitor-previous-value {
    opacity: 0.6;
}
/*css*/
/*.replay-video-streamer {*/
/*    background-image: url(https://cms.duronto.com.bd/upload/content/cms.duronto.com.bd-1612702970-374.gif);*/
/*}*/
.single_movie_title {
    font-weight: 500;
    line-height: 2.4rem;
    color: black
}
.single_movie_title_img {
    height: 33px;
    width: 45px;
    padding: 0px 5px 0px 0px;
}
.single_movie_views {
    font-size: 14px;
    font-weight: 300;
    color: #666;
    text-align: left;
    letter-spacing: 0.5px;
}
.related_videos {
    font-weight: 300;
    line-height: 2.4rem;
    color: black;
}
.player_video{
    text-align: center;
    position: fixed;
    top: -65px;
    left: -32px;
    right: -167px;
}

/*div_css*/
.movieItemInner {
    background: #fff;
    display: block;
    padding: 10px;
    border-radius: 10px;
}

.movieItemInner img {
    max-width: 80px;
    width: 100%;
    height: 65px;
    border-radius: 8px;
}
.quoteItem {
    position: relative;
}

.quoteItem .shareIcon {
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 14px;
    color: #999;
}
.quoteItem {
    margin-bottom: 10px;
}
.quoteItem .shareIcon {
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 14px;
    color: #999;
}

.quItemInner {
    background: #fff;
    display: block;
    padding: 10px;
    border-radius: 10px;
}

/*.quItemInner img {*/
/*    max-width: 80px;*/
/*    width: 100%;*/
/*    height: 65px;*/
/*    border-radius: 8px;*/
/*}*/

.quoitemInnerWrap {
}

.quoitemInnerWrap .info {
    margin: 0;
    padding: 0 0 0 10px;
}

.quoitemInnerWrap .info p {
    font-size: 12px;
    color: #00a;
    margin: 5px;
}

.quoitemInnerWrap .info .qtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: medium;
    text-align: justify;
    color: #000000;
}
.quoitemInnerWrap .info .views {
    font-size: 12px;
    color: #666;
    text-align: left;
    letter-spacing: 0.5px;
}



#question {
    /*background-color: #f56f18;*/
    color: white;
    padding: 0px;
    text-align: left;
    position: absolute;
    top: 35px;
}

#question h4{
    background-color: #fff;
    color: #0b0e21;
    display:inline-block;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
}

#question p{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: justify;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 4px;
}

#answers ul{
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;

    position: relative;
    top: 180px;
}

#answers li {
    background-color: #fff;
    border: 2px solid #ffffff;
    min-height: 45px;
    width: 48%;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 22px;
    transition: color .3s ease, border-color .3s ease, -webkit-transform .3s ease;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    transition: color .3s ease, border-color .3s ease, transform .3s ease, -webkit-transform .3s ease;
    cursor: pointer;
    border-radius: 50px;
}

#answers li span {
    /*background-color: #f56f18;*/
    color: #fff;
    /*font-size: 30px;*/
    -webkit-flex: 75px 1;
            flex: 75px 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    transition: background-color .3s ease;
}

#answers li p {
    color: #0b0e21;
    font-size: 16px;
    font-weight: 600;
    -webkit-flex: calc(100% - 75px) 1;
            flex: calc(100% - 75px) 1;
    margin: auto 20px;
    transition: color .3s ease;
}

#answers li:after{
    display: block;
}

#answers li:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}

#answers li.right {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.right span {*/
/*    background-color: #ffffff;*/
/*}*/

#answers li.right p {
    color: #ffffff;
}

#answers li.wrong {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.wrong span {*/
/*    background-color: #dc3545;*/
/*}*/

#answers li.wrong p {
    color: #ffffff;
}

#submit {
    text-align: center;
    margin: 0px 0;
    position: relative;
    bottom: -165px;
}

.fancy-btn {
    border: 2px solid #0b0e21;
    border-radius: 0;
    background-color: #0b0e21;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 75px;
    margin: 0 auto;
    text-transform: uppercase;
    transition: color .2s ease, background-color .2s ease;
}

.fancy-btn:hover {
    background-color: #fff;
    color: #0b0e21;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.popup {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.popup h1 {
    background-color: #f56f18;
    color: white;
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin-top: 0;
}

.popup p {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 20px 10% 0;
}

.popup .fancy-btn{
    margin: 20px auto;
}

footer {
    background-color: #0b0e21;
    color: white;
    letter-spacing: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
}
footer p {
    margin: 0;
}

footer small {
    margin-bottom: 10px;
}

footer span {
    color: crimson;
}

@media (max-width: 768px) {
    #question p {
        padding-left: 0px;
        padding-right: 10px;
    }

    #answers li {
        width: 100%;
    }

    #submit button {
        /*width: 50%;*/
        /*font-size: 20px;*/
        /*font-weight: 600;*/
        cursor: pointer;
    }
}
@media (max-width: 480px) {

    footer {
        padding: 5px;
    }

    footer small {
        display: none;
    }
}


#question {
    /*background-color: #f56f18;*/
    color: white;
    padding: 0px;
    text-align: left;
    position: absolute;
    top: 35px;
}

#question h4{
    background-color: #fff;
    color: #0b0e21;
    display:inline-block;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
}

#question p{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: justify;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 4px;
}

#answers ul{
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;

    position: relative;
    top: 180px;
}

#answers li {
    background-color: #fff;
    border: 2px solid #ffffff;
    min-height: 45px;
    width: 48%;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 22px;
    transition: color .3s ease, border-color .3s ease, -webkit-transform .3s ease;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    transition: color .3s ease, border-color .3s ease, transform .3s ease, -webkit-transform .3s ease;
    cursor: pointer;
    border-radius: 50px;
}

#answers li span {
    /*background-color: #f56f18;*/
    color: #fff;
    /*font-size: 30px;*/
    -webkit-flex: 75px 1;
            flex: 75px 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    transition: background-color .3s ease;
}

#answers li p {
    color: #0b0e21;
    font-size: 16px;
    font-weight: 600;
    -webkit-flex: calc(100% - 75px) 1;
            flex: calc(100% - 75px) 1;
    margin: auto 20px;
    transition: color .3s ease;
}

#answers li:after{
    display: block;
}

#answers li:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}

#answers li.right {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.right span {*/
/*    background-color: #ffffff;*/
/*}*/

#answers li.right p {
    color: #ffffff;
}

#answers li.wrong {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.wrong span {*/
/*    background-color: #dc3545;*/
/*}*/

#answers li.wrong p {
    color: #ffffff;
}

#submit {
    text-align: center;
    margin: 0px 0;
    position: relative;
    bottom: -165px;
}

.fancy-btn {
    border: 2px solid #0b0e21;
    border-radius: 0;
    background-color: #0b0e21;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 75px;
    margin: 0 auto;
    text-transform: uppercase;
    transition: color .2s ease, background-color .2s ease;
}

.fancy-btn:hover {
    background-color: #fff;
    color: #0b0e21;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.popup {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.popup h1 {
    background-color: #f56f18;
    color: white;
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin-top: 0;
}

.popup p {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 20px 10% 0;
}

.popup .fancy-btn{
    margin: 20px auto;
}

footer {
    background-color: #0b0e21;
    color: white;
    letter-spacing: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
}
footer p {
    margin: 0;
}

footer small {
    margin-bottom: 10px;
}

footer span {
    color: crimson;
}

@media (max-width: 768px) {
    #question p {
        padding-left: 0px;
        padding-right: 10px;
    }

    #answers li {
        width: 100%;
    }

    #submit button {
        /*width: 50%;*/
        /*font-size: 20px;*/
        /*font-weight: 600;*/
        cursor: pointer;
    }
}
@media (max-width: 480px) {

    footer {
        padding: 5px;
    }

    footer small {
        display: none;
    }
}


#question {
    /*background-color: #f56f18;*/
    color: white;
    padding: 0px;
    text-align: left;
    position: absolute;
    top: 35px;
}

#question h4{
    background-color: #fff;
    color: #0b0e21;
    display:inline-block;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
}

#question p{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: justify;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 4px;
}

#answers ul{
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;

    position: relative;
    top: 180px;
}

#answers li {
    background-color: #fff;
    border: 2px solid #ffffff;
    min-height: 45px;
    width: 48%;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 22px;
    transition: color .3s ease, border-color .3s ease, -webkit-transform .3s ease;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    transition: color .3s ease, border-color .3s ease, transform .3s ease, -webkit-transform .3s ease;
    cursor: pointer;
    border-radius: 50px;
}

#answers li span {
    /*background-color: #f56f18;*/
    color: #fff;
    /*font-size: 30px;*/
    -webkit-flex: 75px 1;
            flex: 75px 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    transition: background-color .3s ease;
}

#answers li p {
    color: #0b0e21;
    font-size: 16px;
    font-weight: 600;
    -webkit-flex: calc(100% - 75px) 1;
            flex: calc(100% - 75px) 1;
    margin: auto 20px;
    transition: color .3s ease;
}

#answers li:after{
    display: block;
}

#answers li:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}

#answers li.right {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.right span {*/
/*    background-color: #ffffff;*/
/*}*/

#answers li.right p {
    color: #ffffff;
}

#answers li.wrong {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.wrong span {*/
/*    background-color: #dc3545;*/
/*}*/

#answers li.wrong p {
    color: #ffffff;
}

#submit {
    text-align: center;
    margin: 0px 0;
    position: relative;
    bottom: -165px;
}

.fancy-btn {
    border: 2px solid #0b0e21;
    border-radius: 0;
    background-color: #0b0e21;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 75px;
    margin: 0 auto;
    text-transform: uppercase;
    transition: color .2s ease, background-color .2s ease;
}

.fancy-btn:hover {
    background-color: #fff;
    color: #0b0e21;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.popup {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.popup h1 {
    background-color: #f56f18;
    color: white;
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin-top: 0;
}

.popup p {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 20px 10% 0;
}

.popup .fancy-btn{
    margin: 20px auto;
}

footer {
    background-color: #0b0e21;
    color: white;
    letter-spacing: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
}
footer p {
    margin: 0;
}

footer small {
    margin-bottom: 10px;
}

footer span {
    color: crimson;
}

@media (max-width: 768px) {
    #question p {
        padding-left: 0px;
        padding-right: 10px;
    }

    #answers li {
        width: 100%;
    }

    #submit button {
        /*width: 50%;*/
        /*font-size: 20px;*/
        /*font-weight: 600;*/
        cursor: pointer;
    }
}
@media (max-width: 480px) {

    footer {
        padding: 5px;
    }

    footer small {
        display: none;
    }
}


#question {
    /*background-color: #f56f18;*/
    color: white;
    padding: 0px;
    text-align: left;
    position: absolute;
    top: 35px;
}

#question h4{
    background-color: #fff;
    color: #0b0e21;
    display:inline-block;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
}

#question p{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: justify;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 4px;
}

#answers ul{
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;

    position: relative;
    top: 180px;
}

#answers li {
    background-color: #fff;
    border: 2px solid #ffffff;
    min-height: 45px;
    width: 48%;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 22px;
    transition: color .3s ease, border-color .3s ease, -webkit-transform .3s ease;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    transition: color .3s ease, border-color .3s ease, transform .3s ease, -webkit-transform .3s ease;
    cursor: pointer;
    border-radius: 50px;
}

#answers li span {
    /*background-color: #f56f18;*/
    color: #fff;
    /*font-size: 30px;*/
    -webkit-flex: 75px 1;
            flex: 75px 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    transition: background-color .3s ease;
}

#answers li p {
    color: #0b0e21;
    font-size: 16px;
    font-weight: 600;
    -webkit-flex: calc(100% - 75px) 1;
            flex: calc(100% - 75px) 1;
    margin: auto 20px;
    transition: color .3s ease;
}

#answers li:after{
    display: block;
}

#answers li:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}

#answers li.right {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.right span {*/
/*    background-color: #ffffff;*/
/*}*/

#answers li.right p {
    color: #ffffff;
}

#answers li.wrong {
    border-color: #dc3545;
    color: #1ea55b;
    background-color: #dc3545;
}

/*#answers li.wrong span {*/
/*    background-color: #dc3545;*/
/*}*/

#answers li.wrong p {
    color: #ffffff;
}

#submit {
    text-align: center;
    margin: 0px 0;
    position: relative;
    bottom: -165px;
}

.fancy-btn {
    border: 2px solid #0b0e21;
    border-radius: 0;
    background-color: #0b0e21;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 75px;
    margin: 0 auto;
    text-transform: uppercase;
    transition: color .2s ease, background-color .2s ease;
}

.fancy-btn:hover {
    background-color: #fff;
    color: #0b0e21;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.popup {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.popup h1 {
    background-color: #f56f18;
    color: white;
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin-top: 0;
}

.popup p {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 20px 10% 0;
}

.popup .fancy-btn{
    margin: 20px auto;
}

footer {
    background-color: #0b0e21;
    color: white;
    letter-spacing: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
}
footer p {
    margin: 0;
}

footer small {
    margin-bottom: 10px;
}

footer span {
    color: crimson;
}

@media (max-width: 768px) {
    #question p {
        padding-left: 0px;
        padding-right: 10px;
    }

    #answers li {
        width: 100%;
    }

    #submit button {
        /*width: 50%;*/
        /*font-size: 20px;*/
        /*font-weight: 600;*/
        cursor: pointer;
    }
}
@media (max-width: 480px) {

    footer {
        padding: 5px;
    }

    footer small {
        display: none;
    }
}
.header{
    background:#00a49d;
    padding: 10px 0;
}
.header-innr{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.nav-menu ul{
    margin: 0 -20px;
    padding-top: 5px;
}
.nav-menu ul li{
    float: left;
    padding: 0 20px;
}
.nav-menu ul li a{
    display: inline-block;
    vertical-align: baseline;
    text-decoration: none;
    font-size: 20px;
    line-height: 1.1;
    color: #fff;
}
.nav-menu ul li a:hover{
    color: #ed193f;
    transition: all 0.5s;
    -ms-transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

