.quiz_header {
  text-align: center;
  position: fixed;
  top: -78px;
  left: -32px;
  right: -167px;
}
.quiz_header img {
  width: 100%;
  height: 300px;
}
.quizWinner {
  text-align: center;
}
.quizWinner img {
  width: 100%;
  height: 222px;
}
.duronto_btn {
  width: 180px;
  height: 48px;
  background: #00897a;
  border-radius: 24px;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  color: #fff;
  cursor: pointer;
}

.duronto_btn2 {
  background: #00897a;
  border-radius: 24px;
  color: #fff;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.duronto_btn_success {
  border-radius: 24px;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}
.duronto_next_btn {
  width: 180px;
  height: 48px;
  background: #00897a;
  border-radius: 24px;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  color: #fff;
  cursor: pointer;
}
.duronto_txt {
  color: #00897a;
}

.duronto_yes_btn {
  width: 100px;
  height: 47px;
  background: #1e7e34;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  margin-right: 50px;
  font-size: 22px;
}

.duronto_no_btn {
  width: 100px;
  height: 47px;
  background: #00897a;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  font-size: 22px;
}

.duronto_radio {
  border: 0px;
  width: 100%;
  height: 2em;
}

.footer_quiz {
  /*text-align: center;*/
  /*margin: 20px 0;*/
  /*position: relative;*/
  /*bottom: -188px;*/
}

.footer_quiz img {
  position: fixed;
  bottom: -100px;
  right: -50px;
}

.mainBackground {
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  background-image: url("http://localhost/browser/public/backend/dist/img/bubble.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.counter {
  color: #ffffff;
  font-weight: 500;
  font-size: 25px;
  text-align: right;
  padding-right: 25px;
  padding-top: 5px;
  float: right;
}

.label {
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  padding-top: 5px;
  float: left;
}

.lifeTIme {
  position: fixed;
  top: 2%;
  left: 50%;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  transform: translate(-50%, -50%);
}

.lifeTImeLabel {
  color: #00897a;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}

.labelOne {
  /*margin-left: auto;*/
  margin-top: 130px;
  /*position: relative;*/
  /*top: 100px;*/
  text-align: justify;
}
.labelOneTop {
  color: white;
  margin-top: 30px;
}
.welcome {
  color: red;
  font-size: 22px;
}
.labelOneltext {
  text-align: justify;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.labelContinue {
  text-align: center;
}

.timeOutAndAd {
  padding: 0;
  margin-top: 191px;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  top: 100px;
}

.timeOutAndAd img {
  width: 100%;
  height: 90px;
}

.timeOutAndAd .timeOutText {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
}

.timeOut {
  margin-left: auto;
  margin-right: auto;

  width: 8em;
  padding: 0;
  margin-top: 191px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  top: 100px;

  //text-align: center;
}

.timeOut span {
  margin-left: 5px;
  font-size: 26px;
  font-weight: 700;
}

.timeOut img {
  width: 100%;
  height: 90px;
}

.adText {
  text-align: center;
}

.quiz_rule_heder {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #00897a;
}

.quiz_rule {
  line-height: 32px;
}

.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.asdasdasd > span {
  display: flex;
}

.asdasdasd span span {
  margin-right: 10px;
}
.asdasdasd span.kjkj {
  margin: 0;
}

.failHeader {
  font-size: 28px;
  font-weight: 600;
  color: #00897a;
  padding: 30px 0px 30px 0px;
}

.quizTypeHeader {
  font-size: 28px;
  font-weight: 600;
  color: #00897a;
  padding: 30px 0px 30px 0px;
}

.quizTypeText {
  padding-top: 12px;
}

.quizTypeButton {
  padding-top: 33px;
}

.quizTypeButtonKnow {
  padding-top: 100px;
}

.duronto_type_btn {
  width: 140px;
  height: 48px;
  font-size: 1.25rem;
  background: #00897a;
  border-radius: 24px;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  color: #fff;
  cursor: pointer;
}

.duronto_know_banagladesh_btn {
  width: fit-content;
  height: 48px;
  font-size: 1.25rem;
  background: #00897a;
  border-radius: 24px;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  color: #fff;
  cursor: pointer;
}

.duronto_subject_btn {
  width: 100%;
  height: 48px;
  font-size: 1.25rem;
  background: #00897a;
  border-radius: 24px;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  color: #fff;
  cursor: pointer;
}

.prigeText {
  padding-top: 2px;
}

.fade.model-ad.modal {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #000;
}

.fade.model-ad.modal .modal-dialog.modal-md {
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background: #000;
  position: relative;
  max-width: 100%;
}

.close-btn {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #00897a;
  font-weight: 700;
}

.replay-narrow .replay-controls-bar {
  height: 0px !important;
  align-items: end;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.footerRobi {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 600px;
  margin-left: -299px;
}

.footerRobi img {
  max-width: 130px;
  width: 100%;
  margin: 10px auto 0;
}
